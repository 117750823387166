@use "sass:map";

:root {
  // Theme
  --primary: #5663e9; // used in primary button
  --dark-purple: #2f3ee4; // used as button hover
  --dark-purple2: #1a29cb; // used as button active
  --light-purple: #858eef;
  --light-purple2: #d2d5f9;
  --primary-disabled: #a5abf3;

  // White
  --white: #ffffff;
  --lightwhite-primary: #fefeff;

  // Grey
  --lightgrey-1: #c4c4c4;
  --grey-700: #4f5662;
  --darkgrey-text: #6f7782;

  --bs-body-color: getColor(gray-700);

  //red
  --light-red: #f8406c;
  --dark-red: #df1c4b;
  --invalid: #ff2974;

  // Gradient
  --gradient-01: linear-gradient(135deg, #d585f3 0%, #0a29c2 100%);
  --gradient-02: linear-gradient(135deg, #f385f1 0%, #6615ff 100%);
  --gradient-03: linear-gradient(135deg, #e9bbe8 0%, #c8aaff 100%);
}

// Shadow
$shadow-1: 0px 5px 10px #6f52ed80;
$button-shadow: 1px 2px 6px #5864e9bf;
$button-shadow-large: 1px 2px 10px 1px #5864e9d9;
$shadow-grey: 0px 2px 4px #00000040;
$dashboard-shadow: 1px 2px 10px 1px #00000040;
$node-shadow: 0px 5px 13px #00000040;
$add-tag-button-shadow: #0823303d;

// Navs
$navbar-height: 64px;
$sidebar-width: 240px;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Colors
$palette-map: (
  base-00: #ffffff,
  base-100: #000000,
  blue-25: #f2f5ff,
  blue-50: #e8edff,
  blue-100: #b4c4ff,
  blue-200: #94abff,
  blue-300: #6e8eff,
  blue-400: #4a72ff,
  blue-500: #1b4dff,
  blue-600: #0f3cd9,
  yellow-25: #fff9df,
  yellow-50: #fff2c4,
  yellow-100: #ffe99d,
  yellow-200: #ffe176,
  yellow-400: #ffd027,
  yellow-500: #ffc700,
  yellow-600: #d8a800,
  gray-25: #f9fafb,
  gray-50: #dee1e5,
  gray-100: #c9cdd3,
  gray-200: #b3b8c2,
  gray-300: #AFBACA,
  gray-400: #88909f,
  gray-500: #727c8d,
  gray-700: #4f5662,
  indigo-25: #f4f5ff,
  indigo-50: #dddfff,
  indigo-100: #c6caff,
  indigo-200: #b7bdff,
  indigo-300: #9ca5fd,
  indigo-400: #7d88f4,
  indigo-500: #636dd3,
  indigo-600: #636dd3,
  indigo-700: #3c449f,
  indigo-800: #303783,
  indigo-900: #242967,
  violet-25: #f1ecfe,
  violet-50: #d4c7fd,
  violet-500: #460bf4,
  green-25: #f2fff9,
  green-50: #d7ffeb,
  green-500: #11a75c,
  red-25: #fff5f4,
  red-50: #ffdcda,
  red-500: #ff3838,
  red-600: #e92215,
  red-error: #b3251e,
  pink-25: #fff3f8,
  pink-50: #ffdeec,
  pink-100: #ffbed9,
  pink-200: #ffa0c8,
  pink-300: #fb6ca7,
  pink-400: #e9538d,
  pink-500: #e42f75,
  pink-600: #cf195f,
  pink-700: #bc0f51,
  pink-800: #99053e,
  pink-900: #78002e,
  fushia-50: #f4c9fa,
  fushia-600: #af11c7,
  stone-50: #f3f2ef,
  stone-500: #8e897a,
  sky-25: #f3faff,
  sky-50: #e4f4ff,
  sky-400: #3caafa,
  sky-500: #2497eb,
  lime-100: #d5fd95,
  lime-600: #81c515,
  gold-50: #fff5e4,
  gold-500: #e0bb7f,
  emerald-50: #dbf6ee,
  emerald-200: #7dfeb9,
  emerald-500: #16a077,
  emerald-600: #0a9952,
  zinc-50: #e6e1dd,
  zinc-500: #957f6a,
  cyan-50: #c9f4fb,
  cyan-500: #13cfec,
  rose-50: #ffdadf,
  rose-500: #f54c64,
  purple-50: #e2c7fc,
  purple-500: #820bf4,
  teal-50: #d3f8f2,
  teal-500: #27cab5,
  bluegray-50: #e2e8ee,
  bluegray-500: #365677,
  orange-50: #ffebd8,
  orange-500: #fc8415,
);

$tag-bg-color: (
  tag-bg-1: map.get($palette-map, indigo-50),
  tag-bg-2: map.get($palette-map, pink-50),
  tag-bg-3: map.get($palette-map, blue-50),
  tag-bg-4: map.get($palette-map, yellow-50),
  tag-bg-5: map.get($palette-map, green-50),
  tag-bg-6: map.get($palette-map, blue-100),
  tag-bg-7: map.get($palette-map, red-50),
  tag-bg-8: map.get($palette-map, fushia-50),
  tag-bg-9: map.get($palette-map, yellow-100),
  tag-bg-10: map.get($palette-map, stone-50),
  tag-bg-11: map.get($palette-map, sky-50),
  tag-bg-12: map.get($palette-map, lime-100),
  tag-bg-13: map.get($palette-map, violet-50),
  tag-bg-14: map.get($palette-map, gold-50),
  tag-bg-15: map.get($palette-map, emerald-200),
  tag-bg-16: map.get($palette-map, pink-100),
  tag-bg-17: map.get($palette-map, zinc-50),
  tag-bg-18: map.get($palette-map, cyan-50),
  tag-bg-19: map.get($palette-map, rose-50),
  tag-bg-20: map.get($palette-map, purple-50),
  tag-bg-21: map.get($palette-map, teal-50),
  tag-bg-22: map.get($palette-map, bluegray-50),
  tag-bg-23: map.get($palette-map, orange-50),
  tag-bg-24: map.get($palette-map, emerald-50),
);

$tag-text-color: (
  tag-text-1: map.get($palette-map, indigo-600),
  tag-text-2: map.get($palette-map, pink-500),
  tag-text-3: map.get($palette-map, blue-500),
  tag-text-4: map.get($palette-map, yellow-500),
  tag-text-5: map.get($palette-map, green-500),
  tag-text-6: map.get($palette-map, blue-600),
  tag-text-7: map.get($palette-map, red-600),
  tag-text-8: map.get($palette-map, fushia-600),
  tag-text-9: map.get($palette-map, yellow-600),
  tag-text-10: map.get($palette-map, stone-500),
  tag-text-11: map.get($palette-map, sky-500),
  tag-text-12: map.get($palette-map, lime-600),
  tag-text-13: map.get($palette-map, violet-500),
  tag-text-14: map.get($palette-map, gold-500),
  tag-text-15: map.get($palette-map, emerald-600),
  tag-text-16: map.get($palette-map, pink-600),
  tag-text-17: map.get($palette-map, zinc-500),
  tag-text-18: map.get($palette-map, cyan-500),
  tag-text-19: map.get($palette-map, rose-500),
  tag-text-20: map.get($palette-map, purple-500),
  tag-text-21: map.get($palette-map, teal-500),
  tag-text-22: map.get($palette-map, bluegray-500),
  tag-text-23: map.get($palette-map, orange-500),
  tag-text-24: map.get($palette-map, emerald-500),
);

@function getColor($name) {
  @return map_get($palette-map, $name);
}

@function getTagBgColor($name) {
  @return map_get($tag-bg-color, $name);
}

@function getTagTextColor($name) {
  @return map_get($tag-text-color, $name);
}
