@import "../../scss/abstract/variables";

.navbar {
  z-index: 1;
  color: var(--white);
  height: $navbar-height;
  background: var(--gradient-01);
  box-shadow: $shadow-grey;

  .wave {
    z-index: 2;
    position: absolute;
    fill: rgba(255, 255, 255, 0.6);
  }

  .container {
    &-fluid {
      z-index: 3;
    }

    &__left {
      display: flex; // Flex inside left container
      align-items: center;
      justify-content: space-evenly;

      .hamburger-menu {
        min-width: 32px;

        svg {
          fill: var(--lightwhite-primary);
          width: 24px;
          height: 24px;

          &:hover,
          &:active {
            fill: var(--primary);
            color: var(--primary);
          }
        }
      }

      h3 {
        margin-left: 10px;
        margin-bottom: 0px;
        letter-spacing: 0.5px;
        text-shadow: $shadow-grey;
      }
    }

    .btn {
      .icon {
        fill: var(--black);
        height: 24px;
        width: 24px;

        &__user {
          height: 24px;
          width: 24px;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 1.5rem;

      #budget-year-ddl {
        margin-right: 10px;
      }
    }
  }

  .dropdown-menu .dropdown-item {
    .btn {
      color: getColor(gray-700);
    }

    svg {
      fill: getColor(gray-700);
      stroke: getColor(gray-700);
    }

    &:hover {
      .btn {
        svg {
          fill: var(--primary);
          stroke: var(--primary);
        }
        color: var(--primary);
      }
    }
  }

  .item {
    margin-right: 4rem;
    cursor: pointer;
  }
}

.user-dropdown {
  text-decoration: none;
  margin-left: 1rem;
  color: var(--white);
}
