@import '../abstract/variables.scss';

.app {
  display: flex;
  height: calc(100vh - $navbar-height);
}

.app-container {
  flex: auto;
  overflow: auto;
  background-color: var(--lightwhite-primary);
  min-height: calc(100vh - $navbar-height);
}
